body {
  margin: 0;
  font-family: 'HelveticaNeue', 'Helvetica Neue', 'HelveticaNeueRoman',
    'HelveticaNeue-Roman', 'Helvetica Neue Roman', 'TeXGyreHerosRegular',
    'Helvetica', 'Tahoma', 'Geneva', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** datepicker overrides **/
.DateInput__small {
  margin-left: 1px;
}

.DateInput_input__small {
  line-height: 22px;
  background-color: transparent !important;
  text-align: center;
}

.DateRangePickerInput__withBorder {
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
}

.is-sticky {
  background-color:rgba(230,230,230,0.7) !important;
  backdrop-filter: blur(10px);
}
